// hello
import React, { useRef, useState } from 'react';
import styles from "./index.module.scss"

const Input = ({
  disabled,
  focused,
  filled,
  fullwidth,
  onEmpty,
  type,
  placeholder,
  value,
  onClick,
  error,
  className,
  onChange,
  name,
  success,
  props,
  max,
  maxLength,
  inputRef,
  errorMessage,
  focus,
  onBlur,
  icon,
  onPasswordToggle,
  rightIcon,
  onFocus,
}) => {
  return (
    <div
      className={[styles.form__control, className].join(' ')}
      style={
        error
          ? {
          
              borderRadius: "6px",
              border: '2px solid var(--border-negative)',
              
              color: 'var(--neutral-dark)',
            }
          : success
          ? {
              border: '2px solid #B3BAC5 !important',
              borderRadius: "4px",

              color: 'var(--neutral-dark)',
            }
          : null
      }
    >
      <div className="flex items-center justify-between">
        {rightIcon && (
          <span
            className="px-4 flex items-center justify-center"
            onClick={onPasswordToggle}
          >
            {rightIcon}
          </span>
        )}
        <input
          type={type || 'text'}
          className={[styles.input] }
          placeholder={placeholder || 'this is a placeholder'}
          onFocus={onFocus}
          value={value}
          onClick={onClick}
          onEmptied={onEmpty}
          onBlur={onBlur}
          fullwidth={fullwidth}
          error={error}
          focused={focused || focus}
          filled={filled}
          disabled={disabled}
          onChange={onChange}
          name={name}
          max={max}
          maxLength={maxLength}
          ref={inputRef}
          {...props}
        />
        {icon && (
          <span
            className="px-4 flex items-center justify-center"
            onClick={onPasswordToggle}
          >
            {icon}
          </span>
        )}
      </div>

      <div>{errorMessage}</div>
    </div>
  );
};

export const EditInput = ({
  disabled,
  focused,
  filled,
  fullwidth,
  onEmpty,
  type,
  placeholder,
  value,
  onClick,
  error,
  className,
  onChange,
  name,
  success,
  props,
  max,
  maxLength,
  inputRef,
  errorMessage,
  focus,
  onBlur,
  icon,
  onPasswordToggle,
  rightIcon,
  onFocus,
}) => {
  return (
    <div
      className={[
        ' w-full h-10 my-2 mx-0 hover:opacity-90 transition-colors duration-500 ease-linear bg-[#B3BAC5]',
        className,
      ].join(' ')}
      style={
        error
          ? {
              backgroundColor: 'var(--error-light)',
              border: '2px solid var(--border-negative)',
              color: 'var(--neutral-dark)',
              borderRadius: "6px",

            }
          : success || focus
          ? {
              border: '2px solid #B3BAC5',
              color: 'var(--neutral-dark)',
              borderRadius: "6px",

            }
          : null
      }
    >
      <div className="flex items-center justify-between">
        {rightIcon && (
          <span
            className="px-4 flex items-center justify-center"
            onClick={onPasswordToggle}
          >
            {rightIcon}
          </span>
        )}
        <input
          type={type || 'text'}
          className="h-12 w-full px-0 py-2 border-0 border-transparent text-neutral-mid appearance-none transition-all duration-700 ease-linear focus:border-b-2 focus:border-[#B3BAC5] "
          placeholder={placeholder || 'this is a placeholder'}
          value={value}
          onClick={onClick}
          onEmptied={onEmpty}
          onBlur={onBlur}
          fullwidth={fullwidth}
          error={error}
          focused={focused || focus}
          filled={filled}
          disabled={disabled}
          onChange={onChange}
          name={name}
          max={max}
          maxLength={maxLength}
          ref={inputRef}
          {...props}
          onFocus={onFocus}
          autoComplete="off"
          id="edit-input"
        />
        {icon && (
          <span
            className="px-4 flex items-center justify-center"
            onClick={onPasswordToggle}
          >
            {icon}
          </span>
        )}
      </div>

      <div>{errorMessage}</div>
    </div>
  );
};

export default Input;
