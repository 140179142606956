import React from 'react';
import Link from 'next/link';

import styles from './link.module.scss';

const MainLink = ({ href, text, style, onClick }) => {
  return (
    <Link href={href}>
      <a className={styles.link__text} style={style} onClick={onClick}>
        {text}
      </a>
    </Link>
  );
};

export default MainLink;
