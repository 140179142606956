import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import api from '../../api-client';
import Authlayout from '../../components/widgets/auth-layout';
import SignInWidget from '../../components/widgets/signin';

const SignIn = () => {
  const [userGeo, setUserGeo] = useState({});
  const getUserCountry = React.useCallback(async () => {
    try {
      const res = await api.get('https://get.geojs.io/v1/ip/geo.js');

      setUserGeo(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [userGeo]);

  useEffect(() => {
    getUserCountry();
  }, []);

  return (
    <>
      <Head>
        <title>Signin</title>
        <meta
          name="description"
          content="A Simple Affiliate Cart System That Allows You: Take Affiliate Orders in Dollars & Major African Currencies By Simply Copying & Pasting a Single Line of Code on Your Website"
        />
        <link rel="icon" href="/favicon.png" />
      </Head>
      <Authlayout>

        <SignInWidget userGeo={userGeo} />
      </Authlayout>
     
    </>
  );
};

export async function getServerSideProps(context) {
  const { req, res } = context;
  const token = req.cookies.authToken;

  if (token) {
    // Redirect to dashboard if token exists
    return {
      redirect: {
        destination: '/dashboard/affiliate',
        statusCode: 302,
      },
    };
  }
  return {
    props: {}, // will be passed to the page component as props
  };
}

export default SignIn;
