import React, { useState } from 'react';
import Button from '../../elements/button';
import Card from '../../elements/card';
import Heading, { SmallText, Subheading } from '../../elements/text';
import Input from '../../elements/inputs';
import styles from './signin.module.scss';
import Link from 'next/link';
import { google } from '../../../assets/icons';
import FormDivider from '../../elements/form-divider';
import MainLink from '../../elements/link';
import { Formik, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  loginPending,
  loginSuccess,
  loginFail,
  login,
} from '../../../store/auth/loginSlice';
import { signInValidationSchema } from '../../../utils';
import { loginUser } from '../../../api-client/signin';
import { toast } from 'react-toastify';
import Router, { useRouter } from 'next/router';
import { eyeOff, eye as eyeIcon } from '../../../public/assets/icons';
import Image from 'next/image';
import Authlayout from '../auth-layout';

const SignInWidget = ({ userGeo }) => {
  const dispatch = useDispatch();
  const authState = useSelector(login);
  const router = useRouter();
  const [eye, setEye] = useState(false);
  return (
  

    <section className="">
      <div className=" bg-white">
        <Card className="mt-10   ">
          <header className="mb-8">
            <Heading >
              <p className=" text-left  font-[600] text-[#061938] lg:mt-0 mt-5  lg:text-[30px] leading-[30px] text-[20px] ">

              Welcome Back!
              </p>
              </Heading>
            <Subheading>
              <p className='text-left py-2  text-[11px] lg:text-[16px]'>

              {"Don't have an Account?"}
              <MainLink href="/auth/sign-up" text=" Sign Up" />
              </p>
            </Subheading>
          </header>
          {/* <Button
            className="btn-primary mt-4 mb-4 py-5 px-4 w-full"
            btnIcon={google}
            label="Continue with Google"
          /> */}
          {/* <FormDivider /> */}
          <Formik
            // Initialise Initial Form Values
            initialValues={{ email: '', password: '' }}
            // Function for Error message Display
            validationSchema={signInValidationSchema}
            // Submit Function
            onSubmit={async (data) => {
              dispatch(loginPending());

              const formData = {
                ...data,
                geo: userGeo,
              };

              try {
                const res = await loginUser(formData);
                dispatch(loginSuccess(res.data));

                router.push('/dashboard/affiliate');
              } catch (error) {
                toast.error(error.response.data.message);

                if (error.response.status === 405) {
                  router.push(`/auth/email-verify?email=${data?.email}`);
                }

                dispatch(loginFail());
              }
            }}
          >
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
              <p className='text-[#061938] leading-[20px] text-[10px] lg:text-[13px] '>Email Address</p>
                <Input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  error={errors.email && touched.email}
                  value={values.email}
                  className="border-[1px] rounded-[8px] border-[#B3BAC5]  "
                 
                
                />
                <ErrorMessage name="email">
                  {(msg) => (
                    <SmallText>
                      <p className="error-text"> {msg}</p>
                    </SmallText>
                  )}
                </ErrorMessage>

              <p className='text-[#061938] leading-[20px] text-[10px] lg:text-[13px] mt-4 '>Password</p>

                <Input
                  type={eye ? 'text' : 'password'}
                  icon={<Image src={eye ? eyeIcon : eyeOff} alt="eye icon" />}
                  onPasswordToggle={() => setEye(!eye)}
                  placeholder="Password"
                  name="password"
                  error={errors.password && touched.password}
                  value={values.password}
                  onChange={handleChange}
                  className="border-[1px]  rounded-[8px] border-[#B3BAC5] h-[60px]"
          

                />
                <ErrorMessage name="password">
                  {(msg) => <p className="error-text text-xs">{msg}</p>}
                </ErrorMessage>
                <Button
                  className="btn-primary my-7 py-6 text-[16px] lg:text-[20px] px-4 w-full"
                  label="Log In"
                  type="submit"
                  loading={authState.isLoading}
                />
                  <p
                    className="  text-[10px] lg:text-[12px] leading-[20px] font-[400]"
                    style={{
                      color: '#505F79',
                     
                      textAlign: 'left',
                      cursor: 'pointer',
                      marginTop: "8px"
                    }}
                  >

                    Forgot Password ? {" "}
                <Link href="/auth/reset-password" className="" passHref>
                <span className='text-[#58D96B]'>

               Click to Reset Password
                </span>
                </Link>
                  </p>
              </form>
            )}
          </Formik>
        </Card>
      </div>
    </section>
   
  );
};

export default SignInWidget;
